// src/screens/Welcomec.js
import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import SpecialMenu from '../components/SpecialMenu';
import { getRandomSpread } from '../config/tarotCards';
import '../components/SpecialMenu.css';
import './Welcomec.css';

const Welcomec = () => {
    const [user, setUser] = useState(null);
    const [userName, setUserName] = useState('');
    const [cards, setCards] = useState([]);
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setUserName(userDoc.data().name);
                    setUser(user);
                    setCards(getRandomSpread(3));
                } else {
                    console.log("No such document!");
                }
            } else {
                navigate('/main');
                setUser(null);
                setUserName('');
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    const handleResultClick = async () => {
        if (user) {
            const userRef = doc(db, "users", user.uid);
            try {
                // Обновление данных пользователя с новым раскладом
                await updateDoc(userRef, {
                    tarotSpread: cards
                });
                navigate('/results'); // Перенаправление на страницу результатов
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    return (
        <div className="welcomec-container">
            <SpecialMenu />
            <div className="messagec-box">
                {user ? (
                    <>
                        <h1 style={{ color: 'white' }}>Think about your queston, {userName || 'Anonymous'}</h1>
                        <div className="card-container">
                            {cards.map(card => (
                                <div key={card.name} className={`card ${card.reversed ? "reversed" : ""}`}>
                                    <img src={card.image} alt={card.name} />
                                    <p>{card.name} {card.reversed ? "(Reversed)" : ""}</p>
                                </div>
                            ))}
                        </div>
                        <button className="button" onClick={handleResultClick}>Result</button>
                    </>
                ) : (
                    <h1>Вы не авторизированы</h1>
                )}
            </div>
        </div>
    );
};

export default Welcomec;
