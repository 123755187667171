import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebaseConfig';
import Peer from 'peerjs';
import io from 'socket.io-client';
import './StreamRoom.css';
import SpecialMenu from '../components/SpecialMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { useTokens } from '../contexts/TokenContext';
import { useSession } from '../contexts/SessionContext';
import { v4 as uuidv4 } from 'uuid';



const StreamRoom = () => {
    const navigate = useNavigate();
    const { userId: roomId } = useParams();
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const [user, setUser] = useState(null);
    const [stream, setStream] = useState(null);
    const [isAudioEnabled, setIsAudioEnabled] = useState(true);
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const { setTokens } = useTokens();
    const { startTimer } = useSession();
    const [viewersCount, setViewersCount] = useState(0);

    const toggleMute = () => {
        if (stream && stream.getAudioTracks().length) {
            const enabled = stream.getAudioTracks()[0].enabled;
            stream.getAudioTracks()[0].enabled = !enabled;
            setIsAudioEnabled(!enabled);
        }
    };

    const toggleVideo = () => {
        if (stream && stream.getVideoTracks().length) {
            const enabled = stream.getVideoTracks()[0].enabled;
            stream.getVideoTracks()[0].enabled = !enabled;
            setIsVideoEnabled(!enabled);
        }
    };

    useEffect(() => {
        if (socket) {
            const handleViewersUpdate = ({ viewersCount }) => {
                console.log(`Received viewers update: ${viewersCount}`);
                setViewersCount(viewersCount);
            };
    
            socket.on('viewers-update', handleViewersUpdate);
            return () => socket.off('viewers-update', handleViewersUpdate);
        }
    }, [socket]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const userDocRef = doc(db, "users", authUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    setUser({
                        uid: authUser.uid,
                        name: userDoc.data().name,
                        role: userDoc.data().role
                    });
                    setTokens(userDoc.data().tokens);
                }
            } else {
                console.log('No user is signed in.');
                const randomId = uuidv4(); // Генерируем уникальный UUID
                const randomNumber = Math.floor(Math.random() * 100000); // Генерируем случайное число до 99999
                setUser({
                    uid: randomId,
                    name: `Guest${randomNumber}`,
                    role: 1
            });
            setTokens(0);
            }
        });
    }, [setTokens]);

    useEffect(() => {
        // Восстановление таймера при загрузке страницы
        const savedTimerEnd = localStorage.getItem('timerEnd');
        if (savedTimerEnd) {
            const timeLeft = Math.round((parseInt(savedTimerEnd, 10) - Date.now()) / 1000);
            if (timeLeft > 0) {
                startTimer(timeLeft);
            } else {
                localStorage.removeItem('timerEnd');
            }
        }
    }, [startTimer]);

    

    useEffect(() => {
        if (user) {
            const mediaConstraints = {
                video: true,
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                }
            };
    
            navigator.mediaDevices.getUserMedia(mediaConstraints)
            .then(currentStream => {
                setStream(currentStream); // Сохраняем поток для использования в PeerJS
    
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = currentStream;
                    localVideoRef.current.muted = true; // Отключаем воспроизведение звука локально
                }
            })
            .catch(err => {
                console.error('Error accessing media devices:', err);
            });
        }
    }, [user]);
    
    

    useEffect(() => {
        if (user && stream && roomId) {
            const newPeer = new Peer(user.uid, {
                host: 'taronium.com',
                port: 443,
                path: '/ws3',
                secure: true
            });

            newPeer.on('open', () => {
                const newSocket = io('https://taronium.com', {
                    path: '/ws',
                    secure: true
                });

                newSocket.on('connect', () => {
                    setSocket(newSocket);
                    newSocket.emit('join-room', { roomId, userId: user.uid, role: user.role });
                });

                newSocket.on('connect_error', error => {
                    console.error('Connection error:', error);
                });

                newSocket.on('disconnect', () => {
                    console.error('Socket disconnected');
                });

                newSocket.on('error', error => {
                    console.error('Socket error:', error);
                });

                newPeer.on('error', error => {
                    console.error('Peer error:', error);
                });

                if (user.role === 1) {
                    newSocket.on('streamer-ready', data => {
                        const call = newPeer.call(data.streamerId, stream);
                        call.on('stream', remoteStream => {
                            remoteVideoRef.current.srcObject = remoteStream;
                        });
                    });
                } else {
                    newPeer.on('call', call => {
                        call.answer(stream);
                        call.on('stream', remoteStream => {
                            remoteVideoRef.current.srcObject = remoteStream;
                        });
                    });
                }

                newSocket.on('receive-message', message => {
                    setMessages(prevMessages => [...prevMessages, message]);
                });

                newSocket.on('start-timer', ({ duration }) => {
                    console.log(`Received start-timer event with duration ${duration} seconds in StreamRoom`);
                    startTimer(duration);
                });
            });
        }
    }, [stream, user, roomId, startTimer]);

    const sendMessage = () => {
        if (newMessage.trim() !== "" && socket && socket.connected) {
            const uniqueId = Date.now();
            const message = { id: uniqueId, name: user.name, message: newMessage, roomId };
            socket.emit('send-message', message);
            setMessages(messages => [...messages, message]);
            setNewMessage('');
        } else {
            console.error('Cannot send message: No message content or socket is not connected.');
        }
    };

    const handlePaySession = async () => {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
    
        if (!userDoc.exists()) {
            console.error("User document does not exist!");
            return;
        }
    
        const currentTokens = userDoc.data().tokens;
        console.log("Current tokens before payment:", currentTokens);
        if (currentTokens < 70) {
            navigate('/payment');
            return;
        }
    
        const newTokens = currentTokens - 70;
        await updateDoc(userRef, { tokens: newTokens });
        setTokens(newTokens);
        console.log("New tokens after payment:", newTokens);
    
        if (socket && socket.connected) {
            console.log("Sending payment of 70 tokens to server");
            socket.emit('payment-made', { roomId, amount: 70, userId: user.uid });
            startTimer(900); // Запуск или перезапуск таймера
            console.log("Timer started for 900 seconds after payment");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        const handleTokenUpdate = ({ newTokenCount }) => {
            console.log(`Token update for user ${user ? user.uid : "Unknown user"}: ${newTokenCount}`);
            setTokens(newTokenCount);
        };

        const handleCreditTokens = async ({ amount }) => {
            if (!user || user.role !== 2) { // Убедимся, что текущий пользователь - стример
                return;
            }
            
            console.log(`Received credit of ${amount} tokens`);
            // Обновляем состояние токенов в контексте
            setTokens(prevTokens => prevTokens + amount);

            // Получаем текущий документ пользователя
            const userRef = doc(db, "users", user.uid);
            try {
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    // Считаем новый баланс токенов
                    const newTokens = (userDoc.data().tokens || 0) + amount;
                    console.log(`Updating token balance for user ${user.uid}: new balance is ${newTokens}`);
                    // Обновляем баланс токенов в базе данных
                    await updateDoc(userRef, { tokens: newTokens });
                } else {
                    console.log("User document does not exist!");
                }
            } catch (error) {
                console.error("Failed to update user's tokens:", error);
            }
        };

        const handleStartTimer = ({ duration }) => {
            console.log(`Received start-timer event with duration ${duration} seconds in StreamRoom`);
            startTimer(duration);
        };

        if (socket) {
            console.log("Registering socket event listener for token-update, credit-tokens, and start-timer.");
            socket.on('token-update', handleTokenUpdate);
            socket.on('credit-tokens', handleCreditTokens);
            socket.on('start-timer', handleStartTimer);
        }

        return () => {
            if (socket) {
                console.log("Removing socket event listener for token-update, credit-tokens, and start-timer.");
                socket.off('token-update', handleTokenUpdate);
                socket.off('credit-tokens', handleCreditTokens);
                socket.off('start-timer', handleStartTimer);
            }
        };
    }, [socket, setTokens, user, startTimer]);

    useEffect(() => {
        if (socket) {
            const handleReceiveMessage = (message) => {
                setMessages(prevMessages => {
                    if (!prevMessages.some(msg => msg.id === message.id)) {
                        return [...prevMessages, message];
                    }
                    return prevMessages;
                });
            };

            socket.on('receive-message', handleReceiveMessage);

            return () => {
                socket.off('receive-message', handleReceiveMessage);
            };
        }
    }, [socket]);

    return (
        <div className="stream-room-main-container">
            <SpecialMenu viewersCount={viewersCount} />
            <div className="stream-room-video-container">
                <video ref={localVideoRef} autoPlay playsInline className="stream-room-video" style={{ height: '600px' }}>
                </video>
                <div className="chat-container">
                    <div className="messages">
                        {messages.map((msg, index) => (
                            <p key={index} className={`messa ${user.role === 1 ? 'messa-streamer' : 'messa-viewer'}`}>
                                <b>{msg.name}:</b> {msg.message}
                            </p>
                        ))}
                    </div>
                    <textarea 
                        value={newMessage} 
                        onChange={e => setNewMessage(e.target.value)} 
                        onKeyDown={handleKeyDown}
                        placeholder="Type your text"
                    />
                    <div className="message-controls" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <button onClick={sendMessage} disabled={!socket || !socket.connected}>Send</button>
                        {user && user.role === 1 && (
                            <button onClick={handlePaySession} className="pay-session-button">Pay</button>
                        )}
                    </div>
                </div>
                <div className="video-controls">
                    <button onClick={toggleMute} className={`mute-button ${!isAudioEnabled ? 'background__red' : ''}`}>
                        <FontAwesomeIcon icon={isAudioEnabled ? faMicrophone : faMicrophoneSlash} />
                    </button>
                    <button onClick={toggleVideo} className={`stop-video-button ${!isVideoEnabled ? 'background__red' : ''}`}>
                        <FontAwesomeIcon icon={isVideoEnabled ? faVideo : faVideoSlash} />
                    </button>
                </div>
            </div>
            <div className="stream-room-video-container">
                <video ref={remoteVideoRef} autoPlay playsInline className="stream-room-video" style={{ height: '600px' }} />
            </div>
        </div>
    );
};

export default StreamRoom;
