import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import coinImage from './coin.png';
import { useTokens } from '../contexts/TokenContext';
import { useSession } from '../contexts/SessionContext';
import { useSocket } from '../contexts/SocketContext';

const SpecialMenu = ({ viewersCount }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const socket = useSocket();
    const { tokens, setTokens } = useTokens();
    const { startTimer } = useSession();
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        return onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    setCurrentUser({
                        uid: user.uid,
                        ...userSnap.data(),
                    });
                    setTokens(userSnap.data().tokens);
                }
            } else {
                setCurrentUser(null);
            }
        });
    }, [setTokens]);

    useEffect(() => {
        const updateTimer = () => {
            const timerEnd = localStorage.getItem('timerEnd');
            if (timerEnd) {
                const timeLeft = Math.round((parseInt(timerEnd, 10) - Date.now()) / 1000);
                if (timeLeft > 0) {
                    setTimer(timeLeft);
                } else {
                    setTimer(null);
                    localStorage.removeItem('timerEnd');
                }
            }
        };
        const interval = setInterval(updateTimer, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (socket && currentUser) {
            const handleStartTimer = ({ duration }) => {
                const timerEnd = Date.now() + duration * 1000;
                localStorage.setItem('timerEnd', timerEnd);
                setTimer(duration);
                startTimer(duration);
            };
            socket.on('start-timer', handleStartTimer);
            return () => socket.off('start-timer', handleStartTimer);
        }
    }, [socket, currentUser, startTimer]);

    useEffect(() => {
        if (socket && currentUser) {
            const handleCreditTokens = ({ amount }) => setTokens((prev) => prev + amount);
            const handleTokenUpdate = ({ newTokenCount }) => setTokens(newTokenCount);

            socket.on('credit-tokens', handleCreditTokens);
            socket.on('token-update', handleTokenUpdate);

            return () => {
                socket.off('credit-tokens', handleCreditTokens);
                socket.off('token-update', handleTokenUpdate);
            };
        }
    }, [socket, currentUser, setTokens]);

    const handleTokenClick = () => navigate('/payment');

    if (!currentUser) return null;  // Не отображаем компонент, если пользователь не аутентифицирован

    return (
        <div className="special-menu">
            <Link to="/welcome" className="menu-link">Tarot</Link>
            <Link to="/stream" className="menu-link">Live Streams</Link>
            <Link to="/contact" className="menu-link">Contact us</Link>
            <Link to="/profile" className="menu-link">Profile</Link>
            <div className="tokens" onClick={handleTokenClick} style={{ cursor: 'pointer' }}>
                <img src={coinImage} alt="Coin" className="coin-icon" />
                {tokens} Tokens
            </div>
            {timer && (
                <div className="session-timer" style={{ color: 'yellow', fontWeight: 'bold' }}>
                    Session ends in: {Math.floor(timer / 60)}:{('0' + timer % 60).slice(-2)}
                </div>
            )}
            {currentUser.role === 2 && viewersCount !== undefined && (
                <div className="viewers-count" style={{ color: 'white', fontWeight: 'bold' }}>
                    Viewers: {viewersCount}
                </div>
            )}
        </div>
    );
};

export default SpecialMenu;
