import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB9Et-3jupw-ETMZYM_x0O9Jrh4ekUepsI",
  authDomain: "taronium-7483d.firebaseapp.com",
  projectId: "taronium-7483d",
  storageBucket: "taronium-7483d.appspot.com",
  messagingSenderId: "581581390088",
  appId: "1:581581390088:web:b2f5afec1b099e38a4ecbd",
  measurementId: "G-7BDHGW5J8B"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { db, auth, storage, googleProvider, firebaseConfig };
