import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import SpecialMenu from '../components/SpecialMenu';
import '../components/SpecialMenu.css';
import './Profile.css';

const Profile = () => {
    const { currentUser } = useAuth();
    const [userDetails, setUserDetails] = useState({ name: '', email: '', phone: '', avatarUrl: '' });
    const [avatarFile, setAvatarFile] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');

    useEffect(() => {
        if (currentUser) {
            const userRef = doc(db, "users", currentUser.uid);
            getDoc(userRef).then(docSnap => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setUserDetails({
                        name: userData.name || '',
                        email: userData.email || '',
                        phone: userData.phone || '',
                        avatarUrl: userData.avatarUrl || ''
                    });
                }
            }).catch(error => {
                console.error("Error fetching user details:", error);
            });
        }
    }, [currentUser]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setAvatarFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!currentUser) {
            console.error("User is not logged in.");
            return;
        }

        setIsUpdating(true);
        try {
            if (avatarFile) {
                const avatarRef = ref(storage, `avatar/${currentUser.uid}`);
                await uploadBytes(avatarRef, avatarFile);
                const avatarUrl = await getDownloadURL(avatarRef);
                userDetails.avatarUrl = avatarUrl;
            }

            const userRef = doc(db, "users", currentUser.uid);
            await updateDoc(userRef, {
                name: userDetails.name,
                email: userDetails.email,
                phone: userDetails.phone,
                avatarUrl: userDetails.avatarUrl
            });

            setUpdateMessage("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile:", error);
            setUpdateMessage("Error updating profile.");
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div className="profile-container">
            <SpecialMenu />
            <div className="profile-form">
                <h1 style={{ color: 'white' }}>Profile</h1>
                <form onSubmit={handleSubmit}>
                    <div className="avatar-container">
                        {userDetails.avatarUrl ? (
                            <img src={userDetails.avatarUrl} alt="Avatar" className="avatar" />
                        ) : (
                            <div className="avatar-placeholder">No Avatar</div>
                        )}
                        <input type="file" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div className="user-info">
                        <label>
                            Name:
                            <input type="text" name="name" value={userDetails.name} onChange={handleChange} required />
                        </label>
                        <label>
                            Email:
                            <input type="email" name="email" value={userDetails.email} onChange={handleChange} required readOnly />
                        </label>
                        <label>
                            Phone:
                            <input type="tel" name="phone" value={userDetails.phone} onChange={handleChange} required />
                        </label>
                    </div>
                    <button type="submit" disabled={isUpdating}>Update Profile</button>
                    {updateMessage && <div className="update-message">{updateMessage}</div>}
                </form>
            </div>
        </div>
    );
};

export default Profile;
