import React, { useContext, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import SpecialMenu from '../components/SpecialMenu';
import './Payment.css';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebaseConfig'; // Импортируйте вашу конфигурацию Firebase
import { doc, updateDoc, increment, getDoc } from 'firebase/firestore';

const stripePromise = loadStripe("pk_live_51OcU0YHhpNFiK5bXQNbG3FprDLbskHhtlTFcvzyNX7nGfjXWAiRo0YbmJyruKeGrWO3ADZgQ5k2cC2HFvbcQ2sOj00EeUJVbAN");

const CheckoutForm = ({ onPaymentSuccess }) => {
  const { currentUser } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [priceId, setPriceId] = useState("price_1HjXYeF0rRQe8JsKxKtoqAmT");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleBuy = async (event) => {
    event.preventDefault();

    if (!currentUser?.uid) {
      console.error("Ошибка: ID пользователя не найден. Пользователь должен быть авторизован.");
      return;
    }

    const tokenAmount = priceId === "price_1HjXYeF0rRQe8JsKxKtoqAmT" ? 70 :
                        priceId === "price_2HjXYeF0rRQe8JsKxKtoqAmU" ? 140 : 210;

    const amount = tokenAmount * 100; // Преобразование суммы в центы

    console.log('Начало обработки покупки', { priceId, userId: currentUser.uid, amount });

    try {
      const response = await fetch('https://taronium.com/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount,
          currency: 'usd',
          userId: currentUser.uid
        }),
      });

      if (!response.ok) {
        throw new Error(`Ошибка сети: ${response.statusText}`);
      }

      const { clientSecret } = await response.json();
      console.log('Получен clientSecret от сервера:', clientSecret);

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setErrorMessage("Type Card number");
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: currentUser.email,
          },
        },
      });

      if (result.error) {
        console.error('Ошибка при подтверждении оплаты:', result.error.message);
        setErrorMessage(result.error.message);
      } else {
        console.log('Успешное подтверждение оплаты');

        // Обновление количества токенов в базе данных пользователя
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          tokens: increment(tokenAmount)
        });

        // Получение обновленного значения токенов
        const userDoc = await getDoc(userRef);
        const newTokenBalance = userDoc.data().tokens;

        setErrorMessage(null);
        onPaymentSuccess(newTokenBalance);
      }
    } catch (error) {
      console.error("Ошибка при выполнении покупки:", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <form onSubmit={handleBuy}>
      <div className="price-selection">
        <label>
          <input
            type="radio"
            value="price_1HjXYeF0rRQe8JsKxKtoqAmT"
            checked={priceId === "price_1HjXYeF0rRQe8JsKxKtoqAmT"}
            onChange={(e) => setPriceId(e.target.value)}
          />
          70 Tokens - $70
        </label>
        <label>
          <input
            type="radio"
            value="price_2HjXYeF0rRQe8JsKxKtoqAmU"
            checked={priceId === "price_2HjXYeF0rRQe8JsKxKtoqAmU"}
            onChange={(e) => setPriceId(e.target.value)}
          />
          140 Tokens - $140
        </label>
        <label>
          <input
            type="radio"
            value="price_3HjXYeF0rRQe8JsKxKtoqAmV"
            checked={priceId === "price_3HjXYeF0rRQe8JsKxKtoqAmV"}
            onChange={(e) => setPriceId(e.target.value)}
          />
          210 Tokens - $210
        </label>
      </div>
      <CardElement />
      <button type="submit" className="payment-button">
        Buy
      </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </form>
  );
};

const Payment = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(null);

  const handlePaymentSuccess = (newTokenBalance) => {
    setPaymentSuccess(true);
    setTokenBalance(newTokenBalance);
  };

  return (
    <div className="payment-container">
      <SpecialMenu />
      <div className="button-container">
        <Elements stripe={stripePromise}>
          <CheckoutForm onPaymentSuccess={handlePaymentSuccess} />
        </Elements>
        {paymentSuccess && (
          <div className="success-message">
            Payment Successful! Your new token balance is {tokenBalance}.
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
