import React, { createContext, useState, useContext } from 'react';

const TokenContext = createContext(null);

export const useTokens = () => useContext(TokenContext);

export const TokenProvider = ({ children }) => {
    const [tokens, setTokens] = useState(0);

    return (
        <TokenContext.Provider value={{ tokens, setTokens }}>
            {children}
        </TokenContext.Provider>
    );
};
