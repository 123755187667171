import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js'; // Добавлено расширение .js
import reportWebVitals from './reportWebVitals.js'; // Добавлено расширение .js
import { AuthProvider } from './contexts/AuthContext.js'; // Добавлено расширение .js

// Добавление шима для process и Buffer
import { Buffer } from 'buffer';
import process from 'process';

window.Buffer = Buffer;
window.process = process;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
