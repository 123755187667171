// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #333;
    color: white;
}

.logo img {
    height: 50px;
}

.navigation {
    display: flex;
    align-items: center;
}

.nav-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-left: 20px;
}

.nav-item a {
    color: white;
    text-decoration: none;
}

.nav-item a:hover {
    text-decoration: underline;
}

.login-link {
    margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 10px 20px;\r\n    background-color: #333;\r\n    color: white;\r\n}\r\n\r\n.logo img {\r\n    height: 50px;\r\n}\r\n\r\n.navigation {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.nav-list {\r\n    list-style: none;\r\n    display: flex;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n.nav-item {\r\n    margin-left: 20px;\r\n}\r\n\r\n.nav-item a {\r\n    color: white;\r\n    text-decoration: none;\r\n}\r\n\r\n.nav-item a:hover {\r\n    text-decoration: underline;\r\n}\r\n\r\n.login-link {\r\n    margin-left: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
