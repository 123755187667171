// src/components/FirebaseConnection.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const FirebaseConnection = () => {
    const [connectionStatus, setConnectionStatus] = useState('Connecting...');

    useEffect(() => {
        const testConnection = async () => {
            try {
                await getDocs(collection(db, 'test-collection'));
                setConnectionStatus('Connected to Firebase');
            } catch (error) {
                setConnectionStatus(`Error connecting to Firebase: ${error.message}`);
            }
        };

        testConnection();
    }, []);

    return (
        <div>
            <h2>Firebase Connection Status</h2>
            <p>{connectionStatus}</p>
        </div>
    );
};

export default FirebaseConnection;
