import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth(); // Инициализируем auth здесь

    useEffect(() => {
        // Эффект для подписки на изменения состояния аутентификации
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false); // Устанавливаем loading в false после получения данных пользователя
        });

        return () => {
            unsubscribe(); // Правильно отписываемся при размонтировании компонента
        };
    }, [auth]); // auth добавлен в зависимости для явного контроля изменений

    const value = { currentUser, loading };

    // Рендеринг дочерних компонентов только после загрузки состояния пользователя
    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <div>Loading user...</div>}
        </AuthContext.Provider>
    );
};
