import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import SpecialMenu from '../components/SpecialMenu';
import '../components/SpecialMenu.css';
import './Stream.css';

const Stream = () => {
    const [streamers, setStreamers] = useState([]);
    const navigate = useNavigate(); // Хук для навигации

    useEffect(() => {
        const fetchStreamers = async () => {
            const storage = getStorage();
            const q = query(collection(db, "users"), where("role", "==", 2));
            const querySnapshot = await getDocs(q);
            const streamersList = await Promise.all(querySnapshot.docs.map(async (doc) => {
                const data = doc.data();
                const avatarRef = ref(storage, `avatar/${doc.id}`);
                const avatarUrl = await getDownloadURL(avatarRef).catch(() => '/path/to/default/avatar');
                return { ...data, userId: doc.id, avatarUrl }; // Используем userId вместо id
            }));
            setStreamers(streamersList);
        };

        fetchStreamers();
    }, []);

    const enterStreamerRoom = (userId) => {
        navigate(`/streamroom/${userId}`); // Используем userId для навигации
    };

    return (
        <div className="stream-container">
            <SpecialMenu />
            <div className="streamers-list">
                {streamers.map((streamer) => (
                    <div key={streamer.userId} className="streamer" onClick={() => enterStreamerRoom(streamer.userId)}>
                        <img src={streamer.avatarUrl} alt={streamer.name} className="streamer-avatar" />
                        <div className="streamer-info">
                            <p>{streamer.name}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Stream;
