import React, { useEffect, useState, useCallback } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import { OpenAI } from 'openai';
import { Link } from 'react-router-dom';
import '../components/SpecialMenu.css';
import SpecialMenu from '../components/SpecialMenu';
import './Results.css';

const Results = () => {
    const { currentUser } = useAuth();
    const [tarotSpread, setTarotSpread] = useState([]);
    const [analysisResult, setAnalysisResult] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const performAnalysis = useCallback(async (tarotSpread, question, name) => {
        const prompt = generatePrompt(tarotSpread, question, name);
        const openai = new OpenAI({
            apiKey: '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',
            organization: 'org-KImcLqshTvuNfscQknjbJqFs',
            dangerouslyAllowBrowser: true
        });

        try {
            const response = await openai.chat.completions.create({
                model: "gpt-3.5-turbo",
                messages: [{ "role": "user", "content": prompt }],
                max_tokens: 800,
            });

            const analysisText = response.choices[0]?.message?.content || "Analysis complete with no specific insights.";
            setAnalysisResult(analysisText);
            await setDoc(doc(db, 'users', currentUser.uid), { answer: analysisText }, { merge: true });
        } catch (error) {
            console.error("Error fetching OpenAI data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [currentUser.uid]);

    useEffect(() => {
        if (currentUser) {
            const fetchData = async () => {
                const docRef = doc(db, "users", currentUser.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setTarotSpread(userData.tarotSpread);
                    performAnalysis(userData.tarotSpread, userData.question, userData.name);
                } else {
                    console.log("No such document!");
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [currentUser, performAnalysis]);

    const generatePrompt = (tarotSpread, question, name) => {
        let prompt = `Interpret a question of ${name} - ${question}, with a tarot spread of 3 cards: `;
        tarotSpread.forEach((card, index) => {
            prompt += `${index + 1}. ${card.name} ${card.reversed ? "(Reversed)" : ""}; `;
        });
        return prompt + "\nMake a description of each card considering the question, and then the overall result.";
    };

    if (isLoading) {
        return (
            <div className="loading-container">
                <svg viewBox="0 0 100 100" className="pentagram">
                    <polygon points="50,15 20,85 95,35 5,35 80,85" fill="none" stroke="black" strokeWidth="5"/>
                </svg>
            </div>
        );
    }

    return (
        <div className="results-container">
            <SpecialMenu />
            <div className="tarot-spread">
                {tarotSpread.map((card, index) => (
                    <div key={index} className="tarot-card">
                        <img src={card.image} alt={card.name} className={card.reversed ? "reversed" : ""} />
                        <p>{card.name} {card.reversed ? "(Reversed)" : ""}</p>
                    </div>
                ))}
            </div>
            <div className="analysis-result">
                {analysisResult.split('\n\n').map((part, index) => (
                    <p key={index}>{part}</p>
                ))}
                <p className="next-question-container">
                    <Link to="/welcome" className="next-question-button">Next question</Link>
                </p>
            </div>
        </div>
    );
};

export default Results;
