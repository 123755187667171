import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Добавлен updateDoc
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import SpecialMenu from '../components/SpecialMenu';
import '../components/SpecialMenu.css';
import './Welcome.css';

const Welcome = () => {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [question, setQuestion] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserName(userDoc.data().name);
          setUser(user);
        } else {
          console.log("No such document!");
        }
      } else {
        navigate('/main');
        setUser(null);
        setUserName('');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const handleNext = async () => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      try {
        // Обновление поля question в документе пользователя
        await updateDoc(userRef, {
          question: question
        });
        navigate('/welcomec'); // Перенаправление на страницу Welcome1
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
  };

  return (
    <div className="welcome-container">
      <SpecialMenu />
      <div className="message-box">
        {user ? (
          <>
            <h1 style={{ color: 'white' }}>Welcome, {userName || 'Anonymous'}</h1>
            <p>Ask your question:</p>
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              maxLength={200}
              placeholder="Type your question here..."
              rows={4}
              style={{ width: '90%', padding: '10px', marginBottom: '10px' }}
            />
            <button onClick={handleNext} disabled={!question.trim()}>Next</button> {/* Кнопка будет неактивна, если question пуст */}
          </>
        ) : (
          <h1>Вы не авторизированы</h1>
        )}
      </div>
    </div>
  );
};

export default Welcome;
