import React from 'react';

const About = () => {
    return (
        <div className="about-container">
            <div className="about-content">
                <h1 className="centered-title">Welcome to Taronium</h1>
                <p>At Taronium, we blend the ancient art of tarot with cutting-edge artificial intelligence to offer you personalized insights and predictions. Discover what the cards have in store for you and unlock the secrets of your future today.</p>
                <h2>AI-Powered Tarot Readings</h2>
                <p>Our advanced AI algorithms have been trained on thousands of tarot readings, capturing the deep symbolism and rich interpretations that have guided people for centuries. Each reading is tailored to your unique questions and life situation, providing guidance that is both profound and personal.</p>
                <h2>Palmistry Predictions with a Modern Twist</h2>
                <p>Taronium doesn't stop at tarot. We also bring you the fascinating world of palmistry, modernized with AI precision. Upload a photo of your palm, and our system will analyze the lines and shapes to reveal your character traits, future opportunities, and potential challenges.</p>
                <h2>Features of Taronium:</h2>
                <ul>
                    <li>Personalized Tarot Readings: Get insights on love, career, health, and more.</li>
                    <li>AI-Powered Palm Analysis: Discover the meanings hidden in your palm lines.</li>
                    <li>Daily Horoscopes: Start your day with tailored astrological forecasts.</li>
                    <li>Interactive and Engaging: Enjoy a user-friendly interface and stunning visuals that make your experience magical.</li>
                </ul>
                <p>Embark on a Journey of Discovery</p>
                <p>Whether you're seeking guidance on specific queries or curious about general life trends, Taronium is here to illuminate your path. Trust in the power of AI and the wisdom of the ancients to guide you through life's complexities.</p>
                <p>Connect with Your Destiny</p>
                <p>Visit us at <a href="https://taronium.com">Taronium.com</a> and step into a world where technology meets tradition, and where your future is just a click away. Explore the unknown with Taronium – your most trusted advisor in the journey of life.</p>
                <p className="admin-signature">- Admin</p>
            </div>
        </div>
    );
};

export default About;
