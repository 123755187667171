import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const Registration = () => {
    const navigate = useNavigate();
    const { currentUser, loading } = useAuth();  // Теперь используем currentUser и loading из контекста
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        if (loading) {
            console.log("Auth loading...");
            return; // Ничего не делаем, пока загружается аутентификация
        }

        if (!currentUser) {
            console.log("No user data available, redirecting to login...");
            navigate('/login'); // Перенаправление на страницу входа
            return;
        }

        const userRef = doc(db, "users", currentUser.uid);
        getDoc(userRef).then(docSnap => {
            if (docSnap.exists() && docSnap.data().phone) {
                navigate('/welcome'); // Переходим на приветственную страницу, если телефон уже есть
            } else {
                setEmail(currentUser.email);
                setName(currentUser.displayName || '');
            }
        }).catch(error => {
            console.error("Failed to fetch user data:", error);
        });
    }, [currentUser, loading, navigate]);

    const handleRegistration = async () => {
        if (!name || !email || !phone) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            const userData = {
                name,
                email,
                phone,
                userId: currentUser.uid
            };
            await updateDoc(doc(db, "users", currentUser.uid), userData);
            navigate('/welcome');
        } catch (error) {
            console.error("Error adding document:", error);
            alert(`Failed to register. Please try again. Error: ${error.message}`);
        }
    };

    // Показываем индикатор загрузки, если еще идет аутентификация
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="registration-container">
            <div className="registration-box">
                <h1>Register</h1>
                <input type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} required />
                <input type="email" placeholder="Your Email" value={email} readOnly />
                <input type="text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                <button onClick={handleRegistration}>Register</button>
            </div>
        </div>
    );
};

export default Registration;
