// src/config/tarotCards.js

const tarotCardsConfig = {
    // Major Arcana
    "The Fool": 'cards/major/fool.png',
    "The Magician": 'cards/major/magician.png',
    "The High Priestess": 'cards/major/high_priestess.png',
    "The Empress": 'cards/major/empress.png',
    "The Emperor": 'cards/major/emperor.png',
    "The Hierophant": 'cards/major/hierophant.png',
    "The Lovers": 'cards/major/lovers.png',
    "The Chariot": 'cards/major/chariot.png',
    "Strength": 'cards/major/strength.png',
    "The Hermit": 'cards/major/hermit.png',
    "Wheel Of Fortune": 'cards/major/wheel_of_fortune.png',
    "Justice": 'cards/major/justice.png',
    "The Hanged Man": 'cards/major/hanged_man.png',
    "Death": 'cards/major/death.png',
    "Temperance": 'cards/major/temperance.png',
    "Devil": 'cards/major/devil.png',
    "The Tower": 'cards/major/tower.png',
    "The Star": 'cards/major/star.png',
    "The Moon": 'cards/major/moon.png',
    "The Sun": 'cards/major/sun.png',
    "Judgment": 'cards/major/judgment.png',
    "The World": 'cards/major/world.png',

    // Minor Arcana - Cups
    "Ace of Cups": 'cards/cups/ace.png',
    "Two of Cups": 'cards/cups/two.png',
    "Three of Cups": 'cards/cups/three.png',
    "Four of Cups": 'cards/cups/four.png',
    "Five of Cups": 'cards/cups/five.png',
    "Six of Cups": 'cards/cups/six.png',
    "Seven of Cups": 'cards/cups/seven.png',
    "Eight of Cups": 'cards/cups/eight.png',
    "Nine of Cups": 'cards/cups/nine.png',
    "Ten of Cups": 'cards/cups/ten.png',
    "Page of Cups": 'cards/cups/page.png',
    "Knight of Cups": 'cards/cups/knight.png',
    "Queen of Cups": 'cards/cups/queen.png',
    "King of Cups": 'cards/cups/king.png',

    // Minor Arcana - Pentacles
    "Ace of Pentacles": 'cards/pentacles/ace.png',
    "Two of Pentacles": 'cards/pentacles/two.png',
    "Three of Pentacles": 'cards/pentacles/three.png',
    "Four of Pentacles": 'cards/pentacles/four.png',
    "Five of Pentacles": 'cards/pentacles/five.png',
    "Six of Pentacles": 'cards/pentacles/six.png',
    "Seven of Pentacles": 'cards/pentacles/seven.png',
    "Eight of Pentacles": 'cards/pentacles/eight.png',
    "Nine of Pentacles": 'cards/pentacles/nine.png',
    "Ten of Pentacles": 'cards/pentacles/ten.png',
    "Page of Pentacles": 'cards/pentacles/page.png',
    "Knight of Pentacles": 'cards/pentacles/knight.png',
    "Queen of Pentacles": 'cards/pentacles/queen.png',
    "King of Pentacles": 'cards/pentacles/king.png',

    // Minor Arcana - Swords
    "Ace of Swords": 'cards/swords/ace.png',
    "Two of Swords": 'cards/swords/two.png',
    "Three of Swords": 'cards/swords/three.png',
    "Four of Swords": 'cards/swords/four.png',
    "Five of Swords": 'cards/swords/five.png',
    "Six of Swords": 'cards/swords/six.png',
    "Seven of Swords": 'cards/swords/seven.png',
    "Eight of Swords": 'cards/swords/eight.png',
    "Nine of Swords": 'cards/swords/nine.png',
    "Ten of Swords": 'cards/swords/ten.png',
    "Page of Swords": 'cards/swords/page.png',
    "Knight of Swords": 'cards/swords/knight.png',
    "Queen of Swords": 'cards/swords/queen.png',
    "King of Swords": 'cards/swords/king.png',

    // Minor Arcana - Wands
    "Ace of Wands": 'cards/wands/ace.png',
    "Two of Wands": 'cards/wands/two.png',
    "Three of Wands": 'cards/wands/three.png',
    "Four of Wands": 'cards/wands/four.png',
    "Five of Wands": 'cards/wands/five.png',
    "Six of Wands": 'cards/wands/six.png',
    "Seven of Wands": 'cards/wands/seven.png',
    "Eight of Wands": 'cards/wands/eight.png',
    "Nine of Wands": 'cards/wands/nine.png',
    "Ten of Wands": 'cards/wands/ten.png',
    "Page of Wands": 'cards/wands/page.png',
    "Knight of Wands": 'cards/wands/knight.png',
    "Queen of Wands": 'cards/wands/queen.png',
    "King of Wands": 'cards/wands/king.png',
};

export const tarotCards = Object.keys(tarotCardsConfig);

export const getCardImage = cardName => {
    return require(`../${tarotCardsConfig[cardName]}`);  // Adjusted to use relative path correctly
};

export const getRandomSpread = (spreadSize = 3) => {
    const shuffled = [...tarotCards].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, spreadSize).map(card => ({
        name: card,
        image: getCardImage(card),
        reversed: Math.random() < 0.5 // Randomly determine if the card is reversed
    }));
};
