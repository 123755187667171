import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext(null);

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
    const [timer, setTimer] = useState(null);
    const [tokens, setTokens] = useState(0);

    const startTimer = (duration) => {
        setTimer(duration);
        localStorage.setItem('timerEnd', Date.now() + duration * 1000);
    };

    const stopTimer = () => {
        setTimer(null);
        localStorage.removeItem('timerEnd');
    };

    const updateTokens = (newTokenCount) => {
        setTokens(newTokenCount);
    };

    return (
        <SessionContext.Provider value={{ timer, tokens, startTimer, stopTimer, updateTokens }}>
            {children}
        </SessionContext.Provider>
    );
};
