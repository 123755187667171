// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>&copy; 2024 All rights reserved. <a href='https://voronkin.com/taronium/privacy-policy/' className="footer-link">Privacy Policy </a>| <a href='https://voronkin.com/taronium/privacy-policy/' className="footer-link"> Terms of Service</a></p>
        </footer>
    );
};

export default Footer;