// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("welcome.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
  background-size: cover;
  text-align: center;
  margin-top: -60px; /* Смещаем контейнер вверх для прилегания к хедеру */
  padding-top: 60px; /* Добавляем отступ сверху, чтобы контент не скрывался за хедером */
}


.message-box {
  color: white; /* Белый цвет текста */
  font-size: 24px; /* Размер шрифта для заголовка */
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Небольшая прозрачность для фона текста */
  border-radius: 10px; /* Скругление углов фона текста */
  margin-top: 20px; /* Отступ от меню */
}
`, "",{"version":3,"sources":["webpack://./src/screens/Welcome.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,iFAA6D;EAC7D,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB,EAAE,oDAAoD;EACvE,iBAAiB,EAAE,mEAAmE;AACxF;;;AAGA;EACE,YAAY,EAAE,sBAAsB;EACpC,eAAe,EAAE,gCAAgC;EACjD,aAAa;EACb,oCAAoC,EAAE,2CAA2C;EACjF,mBAAmB,EAAE,iCAAiC;EACtD,gBAAgB,EAAE,mBAAmB;AACvC","sourcesContent":[".welcome-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100vh;\r\n  width: 100%;\r\n  background: url('welcome.webp') no-repeat center center fixed;\r\n  background-size: cover;\r\n  text-align: center;\r\n  margin-top: -60px; /* Смещаем контейнер вверх для прилегания к хедеру */\r\n  padding-top: 60px; /* Добавляем отступ сверху, чтобы контент не скрывался за хедером */\r\n}\r\n\r\n\r\n.message-box {\r\n  color: white; /* Белый цвет текста */\r\n  font-size: 24px; /* Размер шрифта для заголовка */\r\n  padding: 20px;\r\n  background-color: rgba(0, 0, 0, 0.7); /* Небольшая прозрачность для фона текста */\r\n  border-radius: 10px; /* Скругление углов фона текста */\r\n  margin-top: 20px; /* Отступ от меню */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
