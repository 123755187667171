import React, { useEffect, useRef, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import SpecialMenu from '../components/SpecialMenu';
import '../components/SpecialMenu.css';
import './Home.css';

const Home = () => {
    const leftVideoRef = useRef(null);
    const rightVideoRef = useRef(null);
    const [leftVideoStopped, setLeftVideoStopped] = useState(false);
    const [rightVideoStopped, setRightVideoStopped] = useState(false);

    // Используем контекст для доступа к текущему пользователю
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const generateRandomInterval = (min, max) => Math.random() * (max - min) + min;

        let leftIntervalTime = generateRandomInterval(2000, 7000);
        let rightIntervalTime = generateRandomInterval(2000, 7000);

        // Обеспечиваем, что интервалы не равны
        while (Math.abs(leftIntervalTime - rightIntervalTime) < 1000) {
            rightIntervalTime = generateRandomInterval(2000, 7000);
        }

        const leftInterval = setInterval(() => {
            if (!leftVideoStopped) {
                leftVideoRef.current.pause();
                setLeftVideoStopped(true);
            }
        }, leftIntervalTime);

        const rightInterval = setInterval(() => {
            if (!rightVideoStopped) {
                rightVideoRef.current.pause();
                setRightVideoStopped(true);
            }
        }, rightIntervalTime);

        return () => {
            clearInterval(leftInterval);
            clearInterval(rightInterval);
        };
    }, [leftVideoStopped, rightVideoStopped]);

    return (
        <div className="home-container">
            {currentUser && <SpecialMenu />}
            <div className="video-container">
                <video ref={leftVideoRef} autoPlay loop muted>
                    <source src="/tarot-cards.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="video-container">
                <video ref={rightVideoRef} autoPlay loop muted>
                    <source src="/tarot-cards.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default Home;
