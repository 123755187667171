import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../firebaseConfig';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import './Login.css';
import googleLogo from './google_logo.png';

const Login = () => {
    const navigate = useNavigate();
    const db = getFirestore();

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            const userRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                console.log('User exists, checking phone number');
                const userData = docSnap.data();
                if (userData.phone) {
                    console.log('Phone number exists, navigating to welcome');
                    navigate('/welcome');
                } else {
                    console.log('Phone number is missing, navigating to registration to update it');
                    navigate('/registration', { state: { name: user.displayName, email: user.email, userId: user.uid } });
                }
            } else {
                console.log('User does not exist, creating new user and navigating to registration');
                // Создаём новую запись пользователя
                const newUser = {
                    userId: user.uid,
                    email: user.email,
                    name: user.displayName,
                    role: 1,
                    status: 2,
                    subscription: 0,
                    questionsToday: 0,
                    phone: null,
                    tokens: 0,
                    date: new Date().toISOString()
                };
                console.log('Creating new user with data:', newUser);
                await setDoc(userRef, newUser);
                navigate('/registration', { state: { name: user.displayName, email: user.email, userId: user.uid } });
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('Login failed. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h1>Login</h1>
                <button className="google-btn" onClick={signInWithGoogle}>
                    <img src={googleLogo} alt="Google logo" className="google-logo" />
                    Sign in with Google
                </button>
                <p className="register-text">
                    Don't have an account? <a href="https://accounts.google.com/">Register here</a>
                </p>
            </div>
        </div>
    );
};

export default Login;
