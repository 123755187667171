import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png';
import { useAuth } from '../contexts/AuthContext'; // Убедитесь, что путь к AuthProvider корректен
import { getAuth, signOut } from 'firebase/auth'; // Импортируем необходимые функции из Firebase

const Header = () => {
    const { currentUser } = useAuth();
    const location = useLocation();
    const auth = getAuth(); // Получаем экземпляр аутентификации

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log('Successfully logged out');
            })
            .catch((error) => {
                console.error('Logout error:', error);
            });
    };

    return (
        <header className="header">
            <div className="logo">
                {location.pathname !== '/' && (
                    <a href='https://taronium.com/'><img src={logo} alt="Logo" /></a>
                )}
            </div>
            <nav className="navigation">
                <ul className="nav-list">
                    {!currentUser && (
                        <li className="nav-item">
                            <Link to="/about">About us</Link>
                        </li>
                    )}
                    <li className="nav-item login-link">
                        {currentUser ? (
                            <Link to="/" onClick={handleLogout}>Logout</Link>
                        ) : (
                            <Link to="/login">Login</Link>
                        )}
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
