import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, setDoc, serverTimestamp, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import SpecialMenu from '../components/SpecialMenu';
import '../components/SpecialMenu.css';
import './Contact.css';

const Contact = () => {
    const { currentUser } = useAuth();
    const [userDetails, setUserDetails] = useState({ name: '', email: '' });
    const [message, setMessage] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    useEffect(() => {
        if (currentUser) {
            const userRef = doc(db, "users", currentUser.uid);
            getDoc(userRef).then(docSnap => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setUserDetails({ name: userData.name || '', email: userData.email || '' });
                }
            }).catch(error => {
                console.error("Error fetching user details:", error);
            });
        }
    }, [currentUser]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!currentUser) {
            console.error("User is not logged in.");
            return;
        }

        setIsSending(true);
        // Используем doc() для создания нового ID документа в коллекции
        const messageRef = doc(collection(db, "EmailMessages", currentUser.uid, "messages"));

        try {
            await setDoc(messageRef, {
                name: userDetails.name,
                email: userDetails.email,
                message: message,
                timestamp: serverTimestamp()
            });
            console.log("Message sent and saved:", message);
            setMessage('');
            setIsSent(true);
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setIsSending(false);
        }
    };

    if (isSent) {
        return (
            <div className="contact-container">
                <SpecialMenu />
                <div className="success-message">
                    <p>Message sent successfully!</p>
                </div>
            </div>
        );
    }

    return (
        <div className="contact-container">
            <SpecialMenu />
            <div className="contact-form">
                <h1>Contact Us</h1>
                <form onSubmit={handleSubmit}>
                    <div className="user-info">
                        <p>Name: {userDetails.name}</p>
                        <p>Email: {userDetails.email}</p>
                    </div>
                    <textarea id="message" placeholder="Type your message here..." name="message" required value={message} rows={8} onChange={e => setMessage(e.target.value)}></textarea>
                    <button type="submit" disabled={isSending}>Send Message</button>
                </form>
            </div>
        </div>
    );
};

export default Contact;
