import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import Login from './screens/Login';
import Welcome from './screens/Welcome';
import Welcomec from './screens/Welcomec';
import Header from './components/Header';
import Footer from './components/Footer';
import Results from './screens/Results';
import Contact from './screens/Contact';
import Stream from './screens/Stream';
import Payment from './screens/Payment';
import StreamRoom from './screens/StreamRoom';
import Profile from './screens/Profile';
import Registration from './screens/Registration';
import { AuthProvider } from './contexts/AuthContext';
import { TokenProvider } from './contexts/TokenContext';
import { SocketProvider } from './contexts/SocketContext';
import { SessionProvider } from './contexts/SessionContext';
import './App.css';

function App() {
    return (
        <AuthProvider>
            <SessionProvider>
            <TokenProvider>
            <SocketProvider>
            <Router>
                <div className="App">
                    <Header />
                    <main>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/welcome" element={<Welcome />} />
                            <Route path="/welcomec" element={<Welcomec />} />
                            <Route path="/results" element={<Results />} />
                            <Route path="/registration" element={<Registration />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/stream" element={<Stream />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/streamroom/:userId" element={<StreamRoom />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </Router>
           </SocketProvider>
            </TokenProvider>
            </SessionProvider>
        </AuthProvider>
    );
}

export default App;
